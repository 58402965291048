





















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import LayoutMenuModule from "@/store/modules/layout-menu-module";
import { RouterNames } from "@/router/routernames";

@Component({
  components: {},
})
export default class Header extends Vue {
  @Prop() public title!: string;
  public get drawer(): boolean {   
    return LayoutMenuModule.visible;
  }
  public get hm(){
    return RouterNames.Home;
  }
  public set drawer(newtoggle: boolean) {
    LayoutMenuModule.setVisible(newtoggle);
  }
}
