import { store } from "@/store/store";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action
} from 'vuex-module-decorators';

@Module({
  namespaced: true,
  name: 'LayoutMenuModule',
  store,
  dynamic: true
})
class LayoutMenuModule extends VuexModule {
  public visible: boolean = false;

  @Action
  public toggleVisible() {
    this.context.commit('setVisible', !this.visible);
  }

  @Mutation
  public setVisible(newValue: boolean) {
    this.visible = newValue;
  }
}
export default getModule(LayoutMenuModule);
