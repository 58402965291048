import { UtilsString } from "@/utils/utils-string";
import { MenuItem } from "./menu-item";
import { Permission } from "@/shared/permission";
// Cada opción tendrá indicado:
// - ó el "text" con el texto a mostrar (si no se quiere usar multiidioma)
export const MenuOptions: MenuItem[] = [
  {
    icon: "",
    icon_fntaws: "fad fa-home-lg-alt",
    text: "Inicio",
    color: "#673ab7",
    link: "/main/home",
    children: [],
    permission: UtilsString.Empty
  },
  {
    icon: "",
    icon_fntaws: "fad fa-heart",
    text: "Mis dietas",
    color: "#FF917D",
    link: "/main/dietas/menu",
    children: [],
    permission: Permission.is_user
  }, {
    icon: "",
    icon_fntaws: "fad fa-info",
    text: "Información",
    color: "#FF917D",
    link: "/main/acerca",
    children: [],
    permission: Permission.is_apple
  },
  {
    icon: "",
    icon_fntaws: "fad fa-analytics",
    text: "Seguimiento",
    color: "#FFC058",
    link: "/main/seguimiento/menu",
    children: [],
    permission: UtilsString.Empty
  },
  {
    icon: "",
    icon_fntaws: "fad fa-calendar",
    text: "Citas",
    color: "#E9BBA2",
    link: "/main/citas/menu",
    children: [],
    permission: UtilsString.Empty
  },
  {
    icon: "",
    icon_fntaws: "fad fa-shopping-cart",
    text: "Lista de la compra",
    color: "#009BD2",
    link: "/main/lista_compra",
    children: [],
    permission: UtilsString.Empty
  },
  {
    icon: "",
    icon_fntaws: "fad fa-running",
    text: "Ejercicios",
    color: "#01B63E",
    link: "/main/ejercicios/menu",
    children: [],
    permission: UtilsString.Empty
  },
  {
    icon: "",
    icon_fntaws: "fad fa-sign-out-alt",
    text: "Cerrar sessión",
    color: "#C6C6C5",
    link: "/main/cerrar_session",
    children: [],
    permission: UtilsString.Empty
  },
];
